<template>
    <div class="relative">
        <div class="fixed inset-0 flex">
            <div class="w-64 px-2 py-4">
                <div
                    class="flex h-full w-full flex-col items-center rounded-md bg-gray-50 shadow-md"
                >
                    <div
                        class="flex w-full flex-col items-center justify-center gap-1 rounded-t-md border-b border-gray-200 bg-gray-50 py-2"
                    >
                        <span class="text-base font-heading">Store Designer</span>
                    </div>

                    <category-accordion
                        v-model="isOpenen.base"
                        title="Base settings"
                    />
                    <div
                        v-show="isOpenen.base"
                        class="flex w-full items-center justify-center gap-2 p-2"
                    >
                        <label
                            class="text-sm font-medium leading-5 text-gray-700"
                        >
                            Primary Colour:
                        </label>
                        <v-swatches
                            swatch-size="30"
                            :trigger-style="{ width: '26px', height: '26px' }"
                            v-model="formData.primaryColour"
                            show-fallback
                            class="flex items-center justify-center"
                            :swatches="colorSwatches"
                            fallback-input-type="color"
                            fallback-ok-text="Select"
                            @input="
                                onSettingChange(
                                    'primaryColour',
                                    formData.primaryColour
                                )
                            "
                        />
                    </div>

                    <category-accordion
                        v-model="isOpenen.images"
                        title="Images"
                    />
                    <div
                        v-show="isOpenen.images"
                        class="w-full overflow-auto p-2"
                    >
                        <div
                            class="flex w-full flex-col items-center justify-center gap-2"
                        >
                            <image-category
                                title="FAVICON"
                                key-name="faviconImage"
                                :ctx-name="`${ctxName}favImage`"
                                :is-edit-mode="editImage.faviconImage"
                                :image-url="formData.faviconImage || ''"
                                :has-init-value="!!initValues.faviconImage"
                                @success="onImageSuccess"
                                @edit="editImage.faviconImage = true"
                                @cancel-edit="onCancelImageEdit"
                                @image-removed="restoreInitValue"
                            />

                            <image-category
                                title="LOGO"
                                key-name="logo"
                                :ctx-name="`${ctxName}logo`"
                                :is-edit-mode="editImage.logo"
                                :image-url="formData.logo || ''"
                                :has-init-value="!!initValues.logo"
                                @success="onImageSuccess"
                                @edit="editImage.logo = true"
                                @cancel-edit="onCancelImageEdit"
                                @image-removed="restoreInitValue"
                            />

                            <image-category
                                title="LOGO NAV"
                                key-name="logoNav"
                                :ctx-name="`${ctxName}logoNav`"
                                :is-edit-mode="editImage.logoNav"
                                :image-url="formData.logoNav || ''"
                                :has-init-value="!!initValues.logoNav"
                                @success="onImageSuccess"
                                @edit="editImage.logoNav = true"
                                @cancel-edit="onCancelImageEdit"
                                @image-removed="restoreInitValue"
                            />

                            <image-category
                                title="HEADER IMAGE"
                                key-name="headerImage"
                                :ctx-name="`${ctxName}headerImage`"
                                :is-edit-mode="editImage.headerImage"
                                :image-url="formData.headerImage || ''"
                                :has-init-value="!!initValues.headerImage"
                                @success="onImageSuccess"
                                @edit="editImage.headerImage = true"
                                @cancel-edit="onCancelImageEdit"
                                @image-removed="restoreInitValue"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex flex-1 items-center justify-center overflow-hidden p-4"
            >
                <div
                    class="flex h-full w-full flex-col overflow-hidden rounded-md shadow-md"
                    :style="deviceWidth"
                >
                    <div
                        class="flex h-12 w-full items-center justify-start border-b border-gray-200 bg-gray-100 px-6"
                    >
                        <div class="flex w-1/3 flex-none flex-shrink gap-3">
                            <div
                                v-for="index in 3"
                                :key="`button-${index}`"
                                :class="{
                                    'bg-red-500': index === 1,
                                    'bg-yellow-500': index === 2,
                                    'bg-green-500': index === 3
                                }"
                                class="h-2.5 w-2.5 rounded-full"
                            />
                        </div>
                        <div
                            class="h-full w-full sm:w-1/3 self-center justify-self-center p-2"
                        >
                            <div
                                class="flex h-full w-full items-center justify-center gap-1.5 overflow-hidden rounded-md bg-gray-50 px-3 shadow"
                            >
                                <img
                                    v-if="formData.faviconImage"
                                    :src="`${formData.faviconImage}-/resize/16x16/`"
                                    class="rounded-sm"
                                    alt="FavIcon"
                                />
                                <span class="truncate text-sm">
                                    {{ source.text }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <iframe
                        ref="iframe"
                        :src="source.value"
                        scale="0.75"
                        class="flex h-full w-full"
                    />
                </div>
            </div>
            <div class="w-64 px-2 py-4">
                <div
                    class="flex w-full flex-col justify-center overflow-hidden rounded-md bg-white shadow-md"
                >
                    <div
                        class="flex w-full flex-col items-center border-b border-gray-200"
                    >
                        <span
                            class="mb-1 w-full border-b border-gray-200 bg-gray-50 py-4 text-center text-sm"
                        >
                            Device
                        </span>
                        <div class="flex w-full flex-col gap-1 py-1">
                            <label
                                v-for="device in devices"
                                :key="device.value"
                                class="device-label inline-flex w-full items-center gap-2 px-3 py-1"
                                :class="{
                                    'cursor-default bg-gray-200':
                                        selectedDevice === device.value,
                                    'cursor-pointer hover:bg-gray-100':
                                        selectedDevice !== device.value
                                }"
                            >
                                <input
                                    v-model="selectedDevice"
                                    :value="device.value"
                                    type="radio"
                                    class="hidden"
                                />
                                <component :is="device.icon" class="h-6 w-6" />
                                <span class="text-xs"> {{ device.text }}</span>
                            </label>
                        </div>
                    </div>
                    <div class="flex justify-evenly px-1 py-3">
                        <base-button
                            button-text="Preview"
                            button-style="action"
                            size="sm"
                        >
                            <template #left-icon>
                                <base-eye-icon class="mr-2 h-4 w-4" />
                            </template>
                        </base-button>
                        <base-button
                            :disabled="!hasChanged || isLoading"
                            button-text="Publish"
                            button-style="primary"
                            size="sm"
                            @clicked="saveVenue"
                        >
                            <template #left-icon>
                                <base-doc-icon class="mr-2 h-4 w-4" />
                            </template>
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import VSwatches from 'vue-swatches';
import MobileIcon from '@/components/storeDesigner/MobileIcon';
import TabletIcon from '@/components/storeDesigner/TabletIcon';
import DesktopIcon from '@/components/storeDesigner/DesktopIcon';
import ImageCategory from '@/components/storeDesigner/ImageCategory';
import UploadcareInline from '@/components/widgets/UploadcareInline';
import CategoryAccordion from '@/components/storeDesigner/CategoryAccordion';

export default {
    name: 'StoreDesigner',
    metaInfo: {
        title: 'Store Designer'
    },
    props: {
        venueId: {
            type: Number,
            required: true
        },
        venue: {
            type: Object,
            required: true
        }
    },
    components: {
        VSwatches,
        MobileIcon,
        TabletIcon,
        DesktopIcon,
        ImageCategory,
        UploadcareInline,
        CategoryAccordion
    },
    data() {
        const defaultValues = {
            primaryColour: null,
            faviconImage: null,
            headerImage: null,
            logoNav: null,
            logo: null
        };

        return {
            isLoading: false,
            ctxName: this.venue.slug,
            selectedDevice: 'desktop',
            devices: [
                {
                    text: 'Desktop',
                    value: 'desktop',
                    icon: DesktopIcon
                },
                {
                    text: 'Tablet',
                    value: 'tablet',
                    icon: TabletIcon
                },
                {
                    text: 'Mobile',
                    value: 'mobile',
                    icon: MobileIcon
                }
            ],
            colorSwatches: [
                '#000000',
                '#222831',
                '#14213d',
                '#393e46',
                '#583d72',
                '#9f5f80',
                '#99c1de',
                '#84a59d',
                '#ffd369',
                '#ffba93',
                '#f5cac3',
                '#ff8e71'
            ],
            initValues: { ...defaultValues },
            formData: { ...defaultValues },
            isOpenen: {
                base: false,
                images: false
            },
            editImage: {
                faviconImage: false,
                headerImage: false,
                logoNav: false,
                logo: false
            }
        };
    },
    computed: {
        hasChanged() {
            return (
                JSON.stringify(this.formData) !==
                JSON.stringify(this.initValues)
            );
        },
        publicHostname() {
            return process.env.VUE_APP_WHITELABEL_PUBLIC_HOSTNAME;
        },
        source() {
            const isLocal = this.publicHostname === 'localhost:8081';
            const { slug } = this.venue;

            const base = `http${isLocal ? '' : 's'}://${this.publicHostname}`;

            return {
                base,
                text: `${base}/${slug}/menu`,
                value: `${base}/${slug}/menu?storeDesigner=true`
            };
        },
        deviceWidth() {
            switch (this.selectedDevice) {
                case 'desktop':
                    return 'width: 1366px; min-width: 773px';
                case 'tablet':
                    return 'width: 772px; min-width: 640px';
                case 'mobile':
                    return 'width: 390px';
                default:
                    return 'width: 100%';
            }
        }
    },
    mounted() {
        const {
            primaryColour,
            faviconImage,
            headerImage,
            logoNav,
            logo
        } = this.venue;

        this.initValues = {
            primaryColour,
            faviconImage,
            headerImage,
            logoNav,
            logo
        };

        this.formData = { ...this.initValues };
    },

    methods: {
        ...mapActions({
            updateVenueSettings: 'venues/update'
        }),
        async saveVenue() {
            this.isLoading = true;

            try {
                const payload = {
                    id: this.venueId,
                    logo: this.formData.logo,
                    logoNav: this.formData.logoNav,
                    headerImage: this.formData.headerImage,
                    faviconImage: this.formData.faviconImage,
                    primaryColour: this.formData.primaryColour || ''
                };

                await this.updateVenueSettings(payload);

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });

                this.initValues = { ...this.formData };

                Object.keys(this.editImage).forEach(key => {
                    this.editImage[key] = false;
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        onSettingChange(key, value) {
            const message = { key, value };

            this.$refs.iframe.contentWindow.postMessage(
                message,
                this.source.base
            );
        },

        onImageSuccess(key, event) {
            this.formData[key] = event.cdnUrl;

            this.onSettingChange(key, this.formData[key]);
        },

        onCancelImageEdit(key) {
            this.editImage[key] = false;

            this.restoreInitValue(key);
        },

        restoreInitValue(key) {
            if (this.formData[key] !== this.initValues[key]) {
                this.formData[key] = this.initValues[key];

                this.onSettingChange(key, this.formData[key]);
            }
        }
    }
};
</script>
