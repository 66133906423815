<template>
    <div
        class="mx-1 flex w-full cursor-pointer select-none items-center justify-between border-b bg-gray-50 px-3 py-1"
        :class="isOpened ? 'border-gray-100' : 'border-gray-200'"
        @click="isOpened = !isOpened"
    >
        <span class="text-sm font-semibold leading-9 text-gray-700">
            {{ title }}
        </span>
        <span class="text-sm font-medium text-gray-700">
            <svg
                class="h-5 w-5 transition duration-300"
                :class="{ 'rotate-180 transform': isOpened }"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                />
            </svg>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        isOpened: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
};
</script>
