<template>
    <div>
        <label v-if="labelText" class="block text-sm font-medium text-gray-700">
            {{ labelText }}
        </label>
        <span
            @click="$emit('clicked')"
            class="inline-flex rounded-md shadow-sm"
        >
            <component
                :is="element"
                type="button"
                :class="[sizeClass, colourClass, disabledClass, hightlightedClass]"
                :disabled="disabled"
                rel="noreferrer noopener"
                target="_blank"
                :href="href"
                class="focus:outline-none inline-flex items-center rounded-md border font-semibold leading-5 transition duration-150 ease-in-out"
            >
                <slot name="left-icon"></slot>
                {{ buttonText }}
                <slot name="right-icon"></slot>
            </component>
        </span>
    </div>
</template>

<script>
export default {
    name: 'BaseButton',
    props: {
        buttonText: {
            type: String,
            required: true
        },
        labelText: {
            type: String,
            default: null
        },
        buttonStyle: {
            type: String,
            default: null
        },
        size: {
            type: String,
            default: 'md'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        href: {
            type: String,
            required: false,
            default: null
        },
        hightlighted: {
          type: Boolean,
          required: false,
          default: false
        }
    },
    computed: {
        element() {
            return this.href ? 'a' : 'button';
        },
        sizeClass() {
            if (this.size === 'lg') {
                return 'w-7 h-7';
            } else if (this.size === 'sm') {
                return 'px-2 py-1 text-xs';
            }

            else if (this.size === 'xs') {
                return 'px-1.5 py-0.5 text-xs';
            }

            return 'px-4 py-2 text-sm';
        },
        colourClass() {
            if (this.buttonStyle === 'action') {
              return 'border-gray-500 bg-sk-black text-white hover:text-gray-50 focus:border-black focus:shadow-outline-black'
            }
            if (this.buttonStyle === 'primary') {
                return 'bg-indigo-600 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700';
            } else {
                return 'border-gray-300 bg-white text-gray-700 hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800';
            }
        },
        disabledClass() {
            if (this.disabled) {
                return 'opacity-50 cursor-not-allowed';
            }
            return '';
        },
        hightlightedClass() {
            if (this.hightlighted) {
              return 'border-indigo-600 text-indigo-600';
            }
            return '';
        }
    }
};
</script>
