<template>
    <div
        class="relative flex w-0 flex-1 flex-col overflow-hidden print:overflow-visible"
        :class="{ 'cursor-wait opacity-20': isLoading }"
    >
        <base-toggle-sidebar-button v-if="$route.name !== 'liveOrders'" />
        <div
            v-if="order.isDraft"
            class="pointer pointer-events-none absolute right-0 top-0 z-50 flex h-full w-full items-center justify-center"
        >
            <h2
                class="border-2 border-dashed px-3 py-1 text-5xl font-bold uppercase text-red-800 opacity-100"
                >Draft Order</h2
            >
            <div class="absolute h-full w-full bg-black opacity-10"> </div>
        </div>
        <main
            class="focus:outline-none relative z-0 flex-1 overflow-y-auto pb-6 pt-2 md:py-6 print:block print:overflow-y-visible"
        >
            <div
                class="mx-auto flex max-w-5xl flex-col items-center justify-between px-4 sm:items-start sm:px-6 md:flex-row lg:px-8"
            >
                <div class="flex flex-row items-center sm:items-start">
                    <h1
                        class="mr-2 flex items-center text-2xl font-semibold text-gray-500 leading-none"
                    >
                      <DeliveryIcon
                          v-if="FulfillmentMethodsEnum.isDeliveryMethod(order.fulfillmentMethod)"
                          color="text-gray-500"
                      />
                      <PickupIcon
                          v-else-if="FulfillmentMethodsEnum.isPickupMethod(order.fulfillmentMethod)"
                          color="text-gray-500"
                      />
                      <span
                          v-if="FulfillmentMethodsEnum.isCateringMethod(order.fulfillmentMethod)"
                          class="pl-2 font-heading text-sm font-bold text-gray-500"
                      >
                           Catering
                        </span>
                      <span
                          v-if="order.code"
                          class="pl-2 font-heading font-bold text-gray-700"
                      >
                            #{{ order.code }}
                        </span>
                      <span
                          class="pr-2 font-heading font-bold text-gray-700"
                          v-else-if="order.id"
                      >
                            #{{ order.id }}
                        </span>
                    </h1>
                    <venue-name-tag
                        v-if="order.venue && order.venue.name"
                        :name="order.venue.adminName || order.venue.name"
                        class="select-none ml-2 hidden sm:block"
                    />
                </div>

                <div class="flex">
                    <div class="mt-3 flex items-center sm:mt-0">
                        <div
                            class="mr-2 flex flex cursor-pointer flex-col items-center rounded-md px-2 py-1.5 font-medium text-gray-600 hover:bg-gray-200 md:flex-row print:hidden"
                            @click="showDelayOrderModal()"
                        >
                            <span
                                class="hidden pb-2 pr-2 text-sm sm:block md:pb-0"
                            >
                                Delay
                            </span>
                            <svg
                                class="h-4 w-4"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                ></path>
                            </svg>
                        </div>
                      <div
                          v-if="isFeatureAvailable(FeaturesEnum.CATERING) || FulfillmentMethodsEnum.isCateringMethod(order.fulfillmentMethod)"
                          class="mr-2 flex flex cursor-pointer flex-col items-center rounded-md px-2 py-1.5 font-medium text-gray-600 hover:bg-gray-200 md:flex-row print:hidden"
                          @click="printItemLabels()"
                      >
                            <span
                                class="hidden pb-2 pr-2 text-sm sm:block md:pb-0"
                            >
                                Print Item Labels
                            </span>
                        <svg
                            class="h-4 w-4"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                          <path
                              d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                          ></path>
                        </svg>
                      </div>
                        <div
                            class="mr-2 flex flex cursor-pointer flex-col items-center rounded-md px-2 py-1.5 font-medium text-gray-600 md:flex-row print:hidden"
                            :class="
                                printLoading
                                    ? 'opacity-75'
                                    : 'hover:bg-gray-200'
                            "
                            @click="print()"
                        >
                            <span
                                class="hidden pb-2 pr-2 text-sm sm:block md:pb-0"
                            >
                                Print
                            </span>
                            <svg
                                class="h-4 w-4"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                                ></path>
                            </svg>
                        </div>
                        <div
                            v-click-outside="onClickOutside"
                            class="relative inline-block select-none text-left print:hidden"
                        >
                            <div>
                                <span class="rounded-md">
                                    <button
                                        @click="
                                            showMoreActions = !showMoreActions
                                        "
                                        type="button"
                                        class="focus:outline-none inline-flex w-full justify-center rounded-md px-2 py-1.5 text-sm font-medium text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                        id="options-menu"
                                        aria-haspopup="true"
                                        aria-expanded="true"
                                    >
                                        More actions
                                        <svg
                                            class="-mr-1 ml-2 h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                </span>
                            </div>
                            <transition
                                enter-active-class="transition ease-out duration-100"
                                enter-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95"
                            >
                                <div
                                    v-if="showMoreActions"
                                    class="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md shadow-lg"
                                >
                                    <div
                                        class="shadow-xs rounded-md bg-white"
                                        role="menu"
                                        aria-orientation="vertical"
                                        aria-labelledby="options-menu"
                                    >
                                        <div class="py-1">
                                            <a
                                                :href="`https://${publicHostname}/${order.venue.slug}/orders/${order.id}/status`"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                class="focus:outline-none group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                                role="menuitem"
                                            >
                                                <svg
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                                >
                                                    <path
                                                        d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                                                    ></path>
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                                View status page
                                            </a>
                                        </div>
                                        <div class="py-1">
                                            <span
                                                @click="
                                                    showResendOrderConfirmationEmailModal()
                                                "
                                                class="focus:outline-none group flex cursor-pointer items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                                role="menuitem"
                                            >
                                                <svg
                                                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                                Email receipt
                                            </span>
                                        </div>

                                        <!--                                        @TODO Incipio-->
                                        <div
                                            class="py-1"
                                            v-if="
                                                order.accountId ===
                                                    '2f8da1f5-7220-4bc9-ae9c-3a184e486932' ||
                                                order.accountId ===
                                                    'e5dd970e-0afc-4aa7-8787-1f0b7cbc910c' ||
                                                order.accountId ===
                                                '38aad14e-f563-430b-b40c-d3535f700595'
                                            "
                                        >
                                            <span
                                                @click="
                                                    resendReadyForPickupSms()
                                                "
                                                class="focus:outline-none group flex cursor-pointer items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                                role="menuitem"
                                            >
                                                <svg
                                                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="1.5"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                                                    ></path>
                                                </svg>
                                                Resend SMS
                                            </span>
                                        </div>
                                        <div
                                            class="border-t border-gray-100"
                                        ></div>
                                        <div class="py-1">
                                            <span
                                                @click="showArchiveOrderModal()"
                                                class="focus:outline-none group flex cursor-pointer items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                                role="menuitem"
                                            >
                                                <svg
                                                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"
                                                    />
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                                                        clip-rule="evenodd"
                                                    />
                                                </svg>
                                                {{
                                                    this.order.archived
                                                        ? 'Unarchive'
                                                        : 'Archive'
                                                }}
                                            </span>
                                        </div>
                                        <div

                                            v-if="order.fulfillmentMethod !== FulfillmentMethodsEnum.IN_STORE"
                                            class="py-1">
                                            <span
                                                @click="showCancelOrderModal"
                                                class="focus:outline-none group flex cursor-pointer items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                                role="menuitem"
                                            >
                                                <svg
                                                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                                    data-slot="icon"
                                                    fill="none"
                                                    stroke-width="2.5"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"
                                                    ></path>
                                                </svg>
                                                Cancel order
                                            </span>
                                        </div>
                                        <div
                                            class="py-1"
                                            v-if="
                                                !order.posReceiptId &&
                                                !!order.venue.posProvider
                                            "
                                        >
                                            <span
                                                @click="sendOrderToPos()"
                                                class="focus:outline-none group flex cursor-pointer items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                                role="menuitem"
                                            >
                                                <svg
                                                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                                Resend order to POS
                                            </span>
                                        </div>
                                        <div
                                            class="px-2 py-2"
                                            v-if="
                                                order.venue.posProvider ===
                                                    'ikentoo' &&
                                                order.posReceiptId
                                            "
                                        >
                                            <a
                                                :href="
                                                    'https://manager.lsk.lightspeed.app/receipt/list?accountId=' +
                                                    order.posReceiptId
                                                "
                                                target="_blank"
                                                class="focus:outline-none inline-flex w-full items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-xs font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 sm:text-sm"
                                            >
                                                <base-lightspeed-icon />
                                                <span>View in POS</span>
                                            </a>
                                        </div>
                                        <div
                                            class="px-2 py-2"
                                            v-if="
                                                  order.venue.posProvider ===
                                                      'tissl' &&
                                                  order.posReceiptId
                                              "
                                        >
                                          <a
                                              :href="`https://hub.tissl.com/report-centre/order-receipt-details/${order.posReceiptId}`"
                                              target="_blank"
                                              class="focus:outline-none inline-flex w-full items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-xs font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 sm:text-sm"
                                          >
                                            <base-tissl-icon />
                                            <span>View in POS</span>
                                          </a>
                                        </div>
                                        <view-in-deliverect-button
                                            v-if="
                                                order.venue.posProvider ===
                                                    'deliverect' &&
                                                order.posReceiptId
                                            "
                                            :deliverect-id="order.middlewareId ? order.middlewareId : order.posReceiptId"
                                        />
                                        <div
                                            v-if="
                                                isFeatureAvailable(
                                                    FeaturesEnum.INTERNAL
                                                ) &&
                                                order.fulfillmentMethod ===
                                                    'Delivery'
                                            "
                                            class="py-1"
                                        >
                                            <span
                                                @click="mockDelivery()"
                                                class="focus:outline-none group flex cursor-pointer items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                                role="menuitem"
                                            >
                                                <svg
                                                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"
                                                    />
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                                                        clip-rule="evenodd"
                                                    />
                                                </svg>
                                                DEMO Delivery
                                            </span>
                                        </div>
                                        <div
                                            v-if="
                                                  isFeatureAvailable(
                                                      FeaturesEnum.INTERNAL
                                                  )
                                              "
                                            class="py-1"
                                        >
                                              <span
                                                  @click="downloadOrderInvoice()"
                                                  class="focus:outline-none group flex cursor-pointer items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                              >
                                                <base-doc-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" />
                                                  Invoice
                                              </span>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                        <div
                            class="h-4 w-4"
                            :class="{ 'text-gray-900 spinner': isLoading }"
                        >
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mx-auto max-w-5xl sm:px-6 md:px-8">
                <div class="grid grid-cols-3 gap-4 py-4">
                    <div class="col-span-3 lg:col-span-2" v-if="order && order.items">
                        <div
                            v-if="order.orderStatus === 'Failed'"
                            class="border-dashed-2 mb-3 ml-1 overflow-scroll flex items-center justify-between rounded-md border border-red-300 bg-red-50 p-2 font-medium text-red-800 shadow-inner"
                        >
                            <div class="flex items-center">
                                <base-warning-icon
                                    class="mr-2 mt-2 flex-shrink-0 self-start"
                                />
                                <span class="text-sm"
                                    >Something went wrong sending this order to
                                    the POS:
                                    {{
                                        order.posErrorMessage
                                            ? order.posErrorMessage
                                            : 'no reason provided'
                                    }}</span
                                >
                            </div>
                            <div class="ml-2 flex flex-shrink-0 flex-col">
                                <div
                                    v-if="
                                        order.posErrorMessage ===
                                        'Not processed before validity ended'
                                    "
                                    class="flex justify-end"
                                >
                                    <base-button
                                        @clicked="showPosErrorModal"
                                        size="sm"
                                        buttonText="Fix it"
                                    />
                                </div>
                                <div v-else class="flex justify-end">
                                    <base-button
                                        @clicked="openIntercom"
                                        size="sm"
                                        buttonText="Get help"
                                    />
                                </div>
                                <view-in-deliverect-button
                                    v-if="
                                        order.venue.posProvider ===
                                            'deliverect' && order.posReceiptId
                                    "
                                    :deliverect-id="order.middlewareId ? order.middlewareId : order.posReceiptId"
                                />
                            </div>
                        </div>
                        <div
                            class="relative overflow-hidden bg-white shadow print:shadow-none sm:rounded-md"
                        >
                            <div class="px-2 py-2 sm:px-4 sm:py-3 border-b border-gray-100">
                                <div
                                    class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap"
                                >
                                    <div class="ml-4 mt-4">

                                      <div
                                          class="text-sm font-medium flex leading-6 text-gray-900"
                                          v-if="order.table && order.fulfillmentMethod === FulfillmentMethodsEnum.IN_STORE">
                                        <h3>
                                          {{ order.table.name }} ({{
                                            order.table.venueArea.name
                                          }})
                                        </h3>
                                        <div class=" flex items-center">
                                             <span
                                                 class="ml-2 inline-flex rounded-full bg-gray-200 px-2 text-xs font-semibold leading-5 text-gray-400"
                                                 v-if="!order.table.active"
                                             >
                                                  Inactive
                                              </span>
                                          <span
                                              class="ml-2 inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-600"
                                              v-else-if="isSnoozed(order.table)"
                                          >
                                                    Snoozed for
                                                    {{
                                              order.table.disabledUntil
                                                  | moment(
                                                  'from',
                                                  'now',
                                                  true
                                                  )
                                            }}
                                                </span>
                                        </div>
                                      </div>
                                        <h3
                                            v-else-if="order.customer"
                                            class="text-sm font-medium leading-6 text-gray-900"
                                        >
                                          <span
                                              v-if="order.customer.companyName"
                                              class=""
                                          >
                                            {{ order.customer.companyName }}
                                          </span>
                                          <span :class="{ 'text-xs text-gray-600': order.customer.companyName }">
                                            {{ order.customer.firstName }}
                                            {{ order.customer.lastName }}
                                          </span>
                                        </h3>
                                        <p class="text-xs text-gray-500">
                                            {{
                                                order.created_at
                                                    | moment('h:mm A, MMM Do')
                                            }}
                                        </p>

                                    </div>
                                    <div
                                        class="mt-2 flex flex-shrink-0 items-center sm:mt-4"
                                    >
                                        <div
                                            class="ml-4 mt-4 flex-shrink-0"
                                            v-if="
                                                order.orderStatus === 'Pending'
                                            "
                                        >
                                            <span
                                                class="inline-flex rounded-md shadow-sm"
                                            >
                                                <button
                                                    @click="
                                                        updateOrder('Rejected')
                                                    "
                                                    :disabled="
                                                        isStateChangeLoading
                                                    "
                                                    type="button"
                                                    :class="{
                                                        'spinner-white spinner': isStateChangeLoading
                                                    }"
                                                    class="focus:outline-none relative inline-flex items-center rounded-md border-red-400 border bg-transparent font-medium px-4 py-2 text-sm font-medium leading-5 text-red-500 hover:bg-red-100 focus:border-red-700 focus:shadow-outline-red active:bg-red-700"
                                                >
                                                    Reject order
                                                </button>
                                            </span>
                                            <span
                                                class="ml-4 inline-flex rounded-md shadow-sm"
                                            >
                                                <button
                                                    @click="
                                                        updateOrder('Accepted')
                                                    "
                                                    :disabled="
                                                        isStateChangeLoading
                                                    "
                                                    type="button"
                                                    class="cursor-none font-semibold relative inline-flex text-sm items-center rounded-md border border-transparent bg-green-700 px-8 sm:px-16 py-2 leading-5 text-white hover:bg-green-600 focus:border-green-800 focus:outline-none focus:ring-4 focus:ring-green-500 focus:ring-opacity-50 active:bg-green-700"
                                                >
                                                    Accept order
                                                </button>
                                            </span>
                                        </div>
                                        <div
                                            class="ml-4 mt-4 flex-shrink-0"
                                            v-else-if="
                                                order.orderStatus ===
                                                    'Accepted' &&
                                                order.fulfillmentMethod ===
                                                    'Pickup'
                                            "
                                        >
                                            <span
                                                class="ml-4 inline-flex rounded-md shadow-sm"
                                            >
                                                <button
                                                    @click="
                                                        showReadyForPickupModal
                                                    "
                                                    :disabled="
                                                        isStateChangeLoading
                                                    "
                                                    :class="{
                                                        'spinner-white spinner': isStateChangeLoading
                                                    }"
                                                    type="button"
                                                    class="cursor-none font-semibold relative inline-flex text-sm items-center rounded-md border border-transparent bg-green-700 px-8 sm:px-16 py-2 leading-5 text-white hover:bg-green-600 focus:border-green-800 focus:outline-none focus:ring-4 focus:ring-green-500 focus:ring-opacity-50 active:bg-green-700"
                                                >
                                                    Ready for pickup
                                                </button>
                                            </span>
                                        </div>
                                        <div
                                            class="ml-4 mt-4 flex-shrink-0"
                                            v-else-if="
                                                order.orderStatus === StatusEnum.READY_FOR_PICKUP &&
                                                 FulfillmentMethodsEnum.isPickupMethod(order.fulfillmentMethod)
                                            "
                                        >
                                            <span
                                                class="ml-4 inline-flex rounded-md shadow-sm"
                                            >
                                                <button
                                                    @click="
                                                        updateOrder(StatusEnum.COMPLETE)
                                                    "
                                                    :disabled="
                                                        isStateChangeLoading
                                                    "
                                                    :class="{
                                                        'spinner-white spinner': isStateChangeLoading
                                                    }"
                                                    type="button"
                                                    class="cursor-none font-semibold relative inline-flex text-sm items-center rounded-md border border-transparent bg-green-700 px-8 sm:px-16 py-2 leading-5 text-white hover:bg-green-600 focus:border-green-800 focus:outline-none focus:ring-4 focus:ring-green-500 focus:ring-opacity-50 active:bg-green-700"
                                                >
                                                    {{ StatusEnum.COMPLETE }}
                                                </button>
                                            </span>
                                        </div>
                                        <div
                                            class="ml-4 mt-4 flex-shrink-0"
                                            v-else-if="
                                                order.orderStatus ===
                                                    'Accepted' &&
                                                order.fulfillmentMethod ===
                                                    'Delivery'
                                            "
                                        >
                                            <span
                                                class="ml-4 inline-flex rounded-md shadow-sm"
                                            >
                                                <button
                                                    @click="
                                                        updateOrder('Preparing')
                                                    "
                                                    :disabled="
                                                        isStateChangeLoading
                                                    "
                                                    :class="{
                                                        'spinner-white spinner': isStateChangeLoading
                                                    }"
                                                    type="button"
                                                    class="cursor-none font-semibold relative inline-flex text-sm items-center rounded-md border border-transparent bg-green-700 px-8 sm:px-16 py-2 leading-5 text-white hover:bg-green-600 focus:border-green-800 focus:outline-none focus:ring-4 focus:ring-green-500 focus:ring-opacity-50 active:bg-green-700"
                                                >
                                                    Preparing
                                                </button>
                                            </span>
                                        </div>
                                        <div
                                            class="ml-4 mt-4 flex-shrink-0"
                                            v-else-if="
                                                order.orderStatus ===
                                                    'Preparing' &&
                                                order.fulfillmentMethod ===
                                                    'Delivery'
                                            "
                                        >
                                            <span
                                                class="ml-4 inline-flex rounded-md shadow-sm"
                                            >
                                                <button
                                                    @click="
                                                        updateOrder(
                                                            'Out for Delivery'
                                                        )
                                                    "
                                                    :disabled="
                                                        isStateChangeLoading
                                                    "
                                                    :class="{
                                                        'spinner-white spinner': isStateChangeLoading
                                                    }"
                                                    type="button"
                                                    class="cursor-none font-semibold relative inline-flex text-sm items-center rounded-md border border-transparent bg-green-700 px-8 sm:px-16 py-2 leading-5 text-white hover:bg-green-600 focus:border-green-800 focus:outline-none focus:ring-4 focus:ring-green-500 focus:ring-opacity-50 active:bg-green-700"
                                                >
                                                    Out for Delivery
                                                </button>
                                            </span>
                                        </div>
                                        <div
                                            class="ml-4 mt-4 flex-shrink-0"
                                            v-else-if="
                                                order.orderStatus ===
                                                    'Out for Delivery' &&
                                                order.fulfillmentMethod ===
                                                    'Delivery'
                                            "
                                        >
                                            <span
                                                class="ml-4 inline-flex rounded-md shadow-sm"
                                            >
                                                <button
                                                    @click="
                                                        updateOrder('Delivered')
                                                    "
                                                    :disabled="
                                                        isStateChangeLoading
                                                    "
                                                    :class="{
                                                        'spinner-white spinner': isStateChangeLoading
                                                    }"
                                                    type="button"
                                                    class="cursor-none font-semibold relative inline-flex text-sm items-center rounded-md border border-transparent bg-green-700 px-8 sm:px-16 py-2 leading-5 text-white hover:bg-green-600 focus:border-green-800 focus:outline-none focus:ring-4 focus:ring-green-500 focus:ring-opacity-50 active:bg-green-700"
                                                >
                                                    Delivered
                                                </button>
                                            </span>
                                        </div>
                                        <div v-else>
                                            <span
                                                class="rounded bg-gray-200 px-4 py-3 text-sm text-gray-700"
                                            >
                                                {{ order.orderStatus }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <OrkestroChatWidget
                                v-if="deliveryProviderId"
                                :key="deliveryProviderId"
                                :deliveryProviderId="deliveryProviderId"
                                class="absolute bottom-4 right-5"
                            />
                          <div  v-for="delivery in order.deliveries" :key="delivery.id">
                            <DeliveryManagement
                                v-if="displayDeliveryManagement"
                                :delivery="delivery"
                            />
                          </div>
                            <div
                                v-if="order.preOrderDateTime"
                                class="bg-gray-50 px-2 py-2 sm:px-4 sm:py-3"
                            >
                                <div
                                    class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap"
                                >
                                    <div class="ml-4 mt-4">
                                        <p
                                            class="mt-1 text-sm leading-5 text-gray-600"
                                        >
                                            {{ order.fulfillmentMethod }} at
                                          <span
                                              v-tooltip="'Click to change the pre order date and time'"
                                              @click="
                                                $modal.show(
                                                    'edit-pre-order-date-time-modal',
                                                    { order }
                                                )"
                                              class="underline group inline-flex font-medium items-center cursor-pointer hover:font-medium hover:text-gray-800 transition duration-150 ease-in-out"
                                          >
                                            {{
                                                order.preOrderDateTime
                                                    | moment('MMM Do, h:mm A')
                                            }}
                                            <base-edit-icon class="opacity-0 transition duration-150 ease-in-out group-hover:opacity-100 ml-1 text-gray-700" />
                                          </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="px-2 py-2 sm:px-4 sm:py-3"
                                v-if="order.customer && order.customer.notes"
                            >
                                <dl
                                    class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2"
                                >
                                    <div class="sm:col-span-2">
                                        <dt
                                            class="text-sm font-medium leading-5 text-gray-500"
                                        >
                                            Customer Requests
                                        </dt>
                                        <dd
                                            class="mt-1 text-sm leading-5 text-gray-900"
                                        >
                                            <base-warning-icon
                                                class="mr-1 inline-block"
                                            />
                                            {{ order.customer.notes }}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                            <div
                                class="px-2 py-2 sm:px-4 sm:py-3"
                                v-if="order.deliveryAddress?.notes"
                            >
                                <dl
                                    class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2"
                                >
                                    <div class="sm:col-span-2">
                                        <dt
                                            class="text-sm font-medium leading-5 text-gray-500"
                                        >
                                            Delivery Notes
                                        </dt>
                                        <dd
                                            class="mt-1 text-sm leading-5 text-gray-900"
                                        >
                                            <base-warning-icon
                                                class="mr-1 inline-block"
                                            />
                                            {{ order.deliveryAddress.notes }}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                            <order-batch-details
                              v-if="order.batchId"
                              :order-id="order.id"
                              :batch-id="order.batchId" />
                            <order-bill-details
                                v-if="order.billId"
                                :bill-id="order.billId"
                                :venue-id="order.venueId"
                                :order-id="order.id" />

                        </div>

                        <order-line-items :order="order" />
                        <OrderPayments
                            v-if="order.paymentType === 'Adyen'"
                            :order="order"
                            :transactions="order.transactions"
                        />

                        <order-timeline
                            :order="order"
                            :last-delivery="lastDelivery"
                        />
                        <admin-order-json
                            :order="order"
                            v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                        />
                    </div>
                    <div
                        class="col-span-3 overflow-hidden bg-white shadow sm:rounded-lg lg:col-span-2"
                        v-else
                    >
                        <div class="px-4 py-5 sm:px-6">
                            <VclList :width="400" :height="200" />
                        </div>
                    </div>

                    <order-customer-card
                        v-if="order?.customer"
                        v-on:loyalty-reversed="fetchOrder()"
                        :order="order"
                    />

                    <div
                        class="col-span-3 overflow-hidden bg-white shadow sm:rounded-lg lg:col-span-1"
                        v-else
                    >
                        <div class="px-4 py-5 sm:px-6">
                            <VclList :width="250" :height="125" />
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <ArchiveOrderModal v-on:order-archived="orderArchived()" />
        <RefundModal v-on:refund="fetchOrder()" />
        <addDelayModal />
        <ResendOrderEmailConfirmationModal />
        <EditPreOrderDateTimeModal />
        <PosErrorModal />
        <ReadyForPickupModal @update="updateOrder" />
        <cancel-order-modal
            @order-cancelled="orderArchived"
            @show-refund-modal="showRefundModal"
        />
    </div>
</template>

<script>
import { DeliveryProvidersEnum, FulfillmentMethodsEnum, FeaturesEnum, StatusEnum } from '@/enums';
import { mapGetters } from 'vuex';
import ably from '@/helpers/ably';
import { VclList } from 'vue-content-loading';
import vClickOutside from 'v-click-outside';
import ArchiveOrderModal from '@/components/modals/ArchiveOrderModal';
import RefundModal from '@/components/modals/RefundModal';
import addDelayModal from '@/components/modals/addDelayModal';
import PosErrorModal from '@/components/modals/PosErrorModal';
import ResendOrderEmailConfirmationModal from '@/components/modals/ResendOrderEmailConfirmationModal';
import ReadyForPickupModal from '@/components/modals/ReadyForPickupModal';
import DeliveryManagement from '@/components/widgets/DeliveryManagement';
import DeliveryIcon from '@/components/common/DeliveryIcon';
import PickupIcon from '@/components/common/PickupIcon';
import OrderPayments from '@/components/widgets/OrderPayments';
import VenueNameTag from '@/components/common/VenueNameTag';
// import InstoreIcon from '@/components/common/InstoreIcon';
import AdminOrderJson from '@/components/helpers/AdminOrderJson';
import OrderLineItems from '@/components/widgets/OrderLineItems';
import ViewInDeliverectButton from '@/components/widgets/ViewInDeliverectButton';
import OrkestroChatWidget from '@/components/widgets/OrkestroChatWidget';
import OrderCustomerCard from '@/components/OrderCustomerCard';
import EditPreOrderDateTimeModal from '@/components/modals/EditPreOrderDateTimeModal';
import OrderTimeline from '@/components/OrderTimeline';
import OrderBatchDetails from '@/components/OrderBatchDetails';
import OrderBillDetails from '@/components/OrderBillDetails';
import CancelOrderModal from '@/components/modals/CancelOrderModal';

export default {
    name: 'OrderItem',
    metaInfo() {
        return {
            title: `Order #${this.order.code || this.order.id}`
        };
    },
    components: {
        OrderBatchDetails,
        OrderBillDetails,
        PickupIcon,
        VclList,
        RefundModal,
        addDelayModal,
        ArchiveOrderModal,
        DeliveryIcon,
        DeliveryManagement,
        OrderPayments,
        ResendOrderEmailConfirmationModal,
        VenueNameTag,
        PosErrorModal,
        // InstoreIcon,
        AdminOrderJson,
        OrderLineItems,
        ViewInDeliverectButton,
        OrkestroChatWidget,
        ReadyForPickupModal,
        OrderCustomerCard,
        EditPreOrderDateTimeModal,
        OrderTimeline,
        CancelOrderModal
    },
    data() {
        return {
            order: {},
            customer: {},
            isLoading: true,
            isStateChangeLoading: false,
            showMoreActions: false,
            printLoading: false,
            FeaturesEnum,
            deliveryProviderId: null,
            orderChannel: null,
            deliveryChannel: null,
            delivery: null,
            supportedDeliveryProviders: DeliveryProvidersEnum.options,
            FulfillmentMethodsEnum,
            StatusEnum
        };
    },
    beforeDestroy() {
        this.orderChannel ? this.orderChannel.detach() : true;
        this.deliveryChannel ? this.deliveryChannel.detach() : true;
    },
    async created() {
        await this.fetchOrder();
        this.watchOrderChannel();
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    methods: {
        isSnoozed(item) {
          const until = this.$moment(item.disabledUntil);

          return until.isAfter();
        },
        openIntercom() {
            if (window.Intercom) {
                window.Intercom(
                    'showNewMessage',
                    `Hi! I need help fixing an integration issue for the store: (https://order.storekit.com/${this.order.venue.slug}), here's an example order number: ${this.order.id}`
                );
            } else {
                window.alert(
                    'Please disable your AdBlocker to use live chat support.'
                );
            }
        },
        onClickOutside() {
            this.showMoreActions = false;
        },
        async downloadOrderInvoice() {

          try {
            const response = await this.$axios.get(`/orders/${this.order.id}/get-invoice`, {
              responseType: 'blob'
            });

            const pdfBlob = new Blob([response.data], { type: "application/pdf" });
            // Create a temporary URL for the Blob
            const url = window.URL.createObjectURL(pdfBlob);

            // Create a temporary <a> element to trigger the download
            const tempLink = document.createElement("a");
            tempLink.href = url;
            tempLink.setAttribute(
                "download",
                `Order_#${this.order.code}_invoice.pdf`
            ); // Set the desired filename for the downloaded file

            // Append the <a> element to the body and click it to trigger the download
            document.body.appendChild(tempLink);
            tempLink.click();

            // Clean up the temporary elements and URL
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(url);

          }
          catch (error) {
            throw new Error(`API ${error}`);
          }

        },
        async fetchOrder(isRefreshing = false) {
            if (!this.$route.params.id) {
                return;
            }

            if (!isRefreshing) {
                this.isLoading = true;
            }

            try {
                const { data } = await this.$axios.get(
                    `/orders/${this.$route.params.id}/private`
                );

                if (data) {
                    this.order = data;

                    this.deliveryProviderId =
                        data.deliveries[0]?.deliveryProvider === 'orkestro'
                            ? data.deliveries[0]?.deliveryProviderId
                            : null;

                    this.$emit('select', this.order);
                }
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;

                if (!this.deliveryChannel && this.lastDelivery?.id) {
                    this.watchDeliveryChannel()
                }
            }
        },
        async mockDelivery() {
            try {
                await this.$axios.post(
                    `/orders/${this.order.id}/delivery-mock`
                );

                this.$notify({
                    group: 'settings',
                    title: 'Mock delivery started!'
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.showMoreActions = false;
            }
        },
        async updateOrder(val, delay) {
            this.isStateChangeLoading = true;
            let payload = { orderStatus: val };

            try {
                if (delay) {
                    payload.readyForPickupDelay = delay;
                }

                await this.$axios.put(
                    `/orders/${this.$route.params.id}`,
                    payload
                );

                if (
                    val === 'Rejected' &&
                    this.order.paymentType === 'Adyen'
                ) {
                    this.showRefundModal(this.order, true);
                }

                await this.fetchOrder();
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isStateChangeLoading = false;
            }
        },
        showArchiveOrderModal() {
            this.$modal.show('archive-order-modal', {
                orderId: this.order.id,
                isArchived: this.order.archived
            });
        },
        showDelayOrderModal() {
            this.$modal.show('delay-modal', { order: this.order });
        },
        showReadyForPickupModal() {
            this.$modal.show('ready-for-pickup-modal', { order: this.order });
        },
        showResendOrderConfirmationEmailModal() {
            this.$modal.show('resend-order-email-confirmation-modal', {
                orderId: this.order.id,
                customerEmail: this.order.customer.email
            });
        },
        orderArchived() {
          if (this.$route.name !== 'liveOrders') {
            this.$router.push({ name: 'orders' })
          }
        },
        async printItemLabels() {
            try {
              const response = await this.$axios.get(`/orders/${this.order.id}/generate-labels`, {
                responseType: 'blob'
              });

              const pdfBlob = new Blob([response.data], { type: "application/pdf" });
              // Create a temporary URL for the Blob
              const url = window.URL.createObjectURL(pdfBlob);

              // Create a temporary <a> element to trigger the download
              const tempLink = document.createElement("a");
              tempLink.href = url;
              tempLink.setAttribute(
                  "download",
                  `Order_#${this.order.code}_labels.pdf`
              ); // Set the desired filename for the downloaded file

              // Append the <a> element to the body and click it to trigger the download
              document.body.appendChild(tempLink);
              tempLink.click();

              // Clean up the temporary elements and URL
              document.body.removeChild(tempLink);
              window.URL.revokeObjectURL(url);

            }
            catch (error) {
              throw new Error(`API ${error}`);
            }
        },
        print() {
            if (this.order.venue.printers && this.order.venue.printers.length) {
                if (!this.printLoading) {
                    this.cloudprint();
                }

                return;
            }

            let passprnt_uri;

            passprnt_uri = 'starpassprnt://v1/print/nopreview?';

            passprnt_uri =
                passprnt_uri +
                'back=' +
                encodeURIComponent(window.location.href) +
                '&size=3';

            passprnt_uri =
                passprnt_uri +
                '&url=' +
                encodeURIComponent(
                    `https://receipt-service.onrender.com/order/${this.order.id}?venueId=${this.order.venueId}`
                );

            let timeout;

            function preventPopup() {
                clearTimeout(timeout);
                timeout = null;
                window.removeEventListener('pagehide', preventPopup);
            }
            function startPrint() {
                window.location.href = passprnt_uri;
                timeout = setTimeout(function () {
                    window.print();
                }, 2000);
                window.addEventListener('pagehide', preventPopup);
            }

            this.$notify({
                group: 'settings',
                duration: 1000,
                title: 'No printers found, opening the device print dialog'
            });
            startPrint();
        },
        async cloudprint() {
            this.printLoading = true;

            try {
                await this.$axios.post(
                    `/orders/${this.$route.params.id}/print`
                );

                this.$notify({
                    group: 'settings',
                    title: `Print request sent`
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.printLoading = false;
            }
        },
        showRefundModal(order, askToShow) {
            this.$modal.show('refund-modal', { item: order, askToShow });
        },
        async sendOrderToPos() {
            try {
                await this.$axios.post(`/orders/${this.order.id}/pos-retry`);

                this.$notify({
                    group: 'settings',
                    title: 'Queued POS order injection retry'
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async resendReadyForPickupSms() {
            try {
                await this.$axios.post(`/orders/${this.order.id}/sms`);

                this.$notify({
                    group: 'settings',
                    title: 'Sent SMS to customer'
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        showPosErrorModal() {
            this.$modal.show('pos-error-modal', {
                venueSlug: this.order.venue.slug,
                orderId: this.order.id
            });
        },
        watchOrderChannel() {
            const channelName = `order-${this.$route.params.id}`;
            const event = `edit-order-${this.$route.params.id}`;

            this.orderChannel = ably.channels.get(channelName);
            this.orderChannel.subscribe(event, () => {
                this.fetchOrder(true);
            });
        },
        watchDeliveryChannel() {
            this.deliveryChannel = ably.channels.get(`delivery-${this.lastDelivery.id}`);
            this.deliveryChannel.subscribe(`edit-delivery-${this.lastDelivery.id}`, () => {
                this.fetchOrder(true);
            });
        },
        showCancelOrderModal() {
            this.$modal.show('cancel-order-modal', {
                order: this.order
            });
        }
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        }),
        displayDeliveryManagement() {
            if (!this.lastDelivery) {
                return false
            }

            return Object.values(DeliveryProvidersEnum.options).includes(this.lastDelivery.deliveryProvider)
        },
        publicHostname() {
            return process.env.VUE_APP_WHITELABEL_PUBLIC_HOSTNAME;
        },
        lastDelivery() {
            return this.order?.deliveries[0] || null
        },
    },
    watch: {
        '$route.params.id': function () {
            this.fetchOrder();
            this.watchOrderChannel();
        }
    }
};
</script>

<style scoped>
.box-divider {
    box-shadow: inset -1px 0 #e3e8ee;
}

.customer-card {
    height: max-content;
}

@media print {
    table {
        page-break-inside: auto;
    }
    tr {
        page-break-inside: avoid;
        page-break-after: auto;
    }
    thead {
        display: table-header-group;
    }
    tfoot {
        display: table-footer-group;
    }

    .map-container {
        height: 500px;
        width: 1000px;
    }
}
</style>
