<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex inline-flex items-center text-sm text-gray-500"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>

                <base-page-title title="Pre-Orders" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Pre-Orders</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Enable to allow customers to order in advance.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="grid grid-cols-6 gap-3">
                        <div
                            class="col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"
                        >
                            <div class="mt-5 md:col-span-2 md:mt-0">
                                <div
                                    class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6"
                                >
                                    <div class="sm:col-span-6">
                                        <p
                                            class="mt-1 text-sm leading-5 text-gray-500"
                                        >
                                        </p>
                                        <div class="mt-3 flex items-start">
                                            <toggle-switch
                                                :value="
                                                    slotSettings.acceptsPreOrders
                                                "
                                                @input="
                                                    updateVenue(
                                                        'acceptsPreOrders'
                                                    )
                                                "
                                            />
                                            <label
                                                class="pl-4 text-sm text-gray-500"
                                            >
                                                Enable to allow customers to
                                                order in advance.
                                            </label>
                                        </div>

                                        <div class="mt-3 flex items-start">
                                            <toggle-switch
                                                :value="
                                                    slotSettings.alwaysOpenForPreorders
                                                "
                                                @input="
                                                    updateVenue(
                                                        'alwaysOpenForPreorders'
                                                    )
                                                "
                                            />
                                            <label
                                                class="pl-4 text-sm text-gray-500"
                                            >
                                                Enable to allow customers to
                                                order outside of venue opening
                                                hours.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <select
                @change="selectTab($event.target.value)"
                id="tabs"
                name="tabs"
                class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            >
                <option value="pickup">Pickup</option>
                <option value="delivery" selected>Delivery</option>
            </select>
        </div>
        <div class="mt-4 hidden sm:block md:grid md:grid-cols-3 md:gap-6">
            <div class="border-b border-gray-200 md:col-span-2 md:col-start-2">
                <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                    <span
                        :class="[
                            isPickup
                                ? 'border-indigo-500 text-indigo-600'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        ]"
                        @click="selectTab('pickup')"
                        class="group inline-flex cursor-pointer items-center border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                        <PickupIcon
                            class="-ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            :class="[isPickup ? 'text-indigo-500' : '']"
                        />
                        <span>Pickup</span>
                    </span>
                    <span
                        @click="selectTab('delivery')"
                        :class="[
                            !isPickup
                                ? 'border-indigo-500 text-indigo-600'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        ]"
                        class="group relative inline-flex cursor-pointer items-center border-b-2 px-1 py-4 text-sm font-medium"
                    >
                        <DeliveryIcon
                            class="-ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            :class="[isPickup ? '' : 'text-indigo-500']"
                        />

                        <span>Delivery</span>
                    </span>
                </nav>
            </div>
        </div>

        <div v-if="selectedTab === 'pickup'">
            <div>
                <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                        <div class="sm:pl-0 sm:pr-5">
                            <h3
                                class="text-base font-semibold leading-6 text-gray-800"
                                >Pickup Details</h3
                            >
                            <p class="mt-2 text-sm text-gray-500">
                                Configure your how you'd like to receive
                                pre-orders
                            </p>
                        </div>
                    </div>
                    <div class="mt-5 md:col-span-2 md:mt-0">
                        <div class="grid grid-cols-6 gap-3">
                            <div
                                class="col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"
                            >
                                <div class="mt-5 md:col-span-2 md:mt-0">
                                    <div
                                        class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6"
                                    >
                                        <div
                                            :class="{
                                                'pointer-events-none cursor-not-allowed opacity-50': !acceptsPreOrders
                                            }"
                                            class="sm:col-span-6 md:pr-2"
                                        >
                                            <label
                                                for="slotDuration"
                                                class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Time slot length
                                            </label>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                Split up the day into multiple
                                                time slots customers can choose
                                                from when they order.
                                            </p>
                                            <div
                                                class="mt-1 sm:col-span-2 sm:mt-2"
                                            >
                                                <div
                                                    class="max-w-xs rounded-md shadow-sm"
                                                >
                                                    <select
                                                        id="slotDuration"
                                                        v-model="
                                                            pickupSettings.slotInterval
                                                        "
                                                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                                    >
                                                        <option
                                                            :value="duration"
                                                            v-for="duration in slotDurations"
                                                            :key="duration"
                                                            >{{
                                                                duration
                                                            }}
                                                            Minutes</option
                                                        >
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            :class="{
                                                'pointer-events-none cursor-not-allowed opacity-50': !acceptsPreOrders
                                            }"
                                            class="sm:col-span-6 md:pl-2"
                                        >
                                            <label
                                                for="slotQuantity"
                                                class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Max orders per slot
                                            </label>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                Limit the number of orders you
                                                allow per time slot. Leave empty
                                                for unlimited orders per time
                                                slot.
                                            </p>
                                            <div
                                                class="mt-1 sm:col-span-2 sm:mt-2"
                                            >
                                                <div
                                                    class="max-w-xs rounded-md shadow-sm"
                                                >
                                                    <input
                                                        type="number"
                                                        v-model="
                                                            pickupSettings.pickupSlotQuantity
                                                        "
                                                        placeholder="Unlimited"
                                                        id="slotQuantity"
                                                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            :class="{
                                                'pointer-events-none cursor-not-allowed opacity-50': !acceptsPreOrders
                                            }"
                                            class="sm:col-span-6 md:pl-2"
                                        >
                                            <label
                                                for="slotQuantity"
                                                class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Max items per slot
                                            </label>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                Limit the number of items you
                                                allow per time slot. Leave empty
                                                for unlimited items per time
                                                slot.
                                            </p>
                                            <div
                                                class="mt-1 sm:col-span-2 sm:mt-2"
                                            >
                                                <div
                                                    class="max-w-xs rounded-md shadow-sm"
                                                >
                                                    <input
                                                        type="number"
                                                        v-model="
                                                            pickupSettings.itemQuantity
                                                        "
                                                        placeholder="Unlimited"
                                                        id="slotQuantity"
                                                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            :class="{
                                                'pointer-events-none cursor-not-allowed opacity-50': !acceptsPreOrders
                                            }"
                                            class="sm:col-span-6 md:pl-2"
                                        >
                                            <label
                                                for="pickupSettingsMonetaryLimit"
                                                class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Monetary limit per slot
                                            </label>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                Limit the total amount of orders
                                                allowed for the time slot by the
                                                sum of order totals. E.g. set a
                                                limit of £200 and customers will
                                                be prevented from ordering more
                                                within a timeslot.
                                            </p>
                                            <div
                                                class="mt-1 sm:col-span-2 sm:mt-2"
                                            >
                                                <div
                                                    class="relative mt-1 max-w-xs rounded-md shadow-sm"
                                                >
                                                    <div
                                                        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                                    >
                                                        <span
                                                            class="text-gray-500 sm:text-sm sm:leading-5"
                                                        >
                                                            {{
                                                                currency.symbol
                                                            }}
                                                        </span>
                                                    </div>
                                                    <currency-input
                                                        id="pickupSettingsMonetaryLimit"
                                                        v-model="
                                                            pickupSettings.monetaryLimit
                                                        "
                                                        class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                                        placeholder="Unlimited"
                                                        :currency="null"
                                                        locale="en-GB"
                                                        :value-as-integer="true"
                                                        :allow-negative="false"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            :class="{
                                                'pointer-events-none cursor-not-allowed opacity-50': !acceptsPreOrders
                                            }"
                                            class="sm:col-span-6 md:pl-2"
                                        >
                                            <label
                                                for="maxFutureDays"
                                                class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Limit future date selection
                                            </label>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                How far in advance customers can
                                                place orders (in days).
                                            </p>
                                            <div
                                                class="mt-1 sm:col-span-2 sm:mt-2"
                                            >
                                                <div
                                                    class="max-w-xs rounded-md shadow-sm"
                                                >
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        v-model="
                                                            pickupSettings.maxFutureDays
                                                        "
                                                        placeholder="Unlimited"
                                                        id="maxFutureDays"
                                                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                                        :class="{
                                                            'border-red-300':
                                                                $v
                                                                    .pickupSettings
                                                                    .maxFutureDays
                                                                    .$error
                                                        }"
                                                    />
                                                </div>
                                                <span
                                                    v-if="
                                                        $v.pickupSettings
                                                            .maxFutureDays
                                                            .$error
                                                    "
                                                    class="mt-1 text-xs text-red-300"
                                                >
                                                    Must be 1 or greater
                                                </span>
                                            </div>
                                        </div>

                                        <div
                                            :class="{
                                                'pointer-events-none cursor-not-allowed opacity-50': !acceptsPreOrders
                                            }"
                                            class="sm:col-span-6 md:pl-2"
                                        >
                                            <label
                                                for="prepTimeInDays"
                                                class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Order cut-off
                                            </label>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                Enter a number of days you'll
                                                need to prepare for pickup.
                                                Enter 0 for same day pickup.
                                            </p>
                                            <div
                                                class="mt-1 sm:col-span-2 sm:mt-2"
                                            >
                                                <div
                                                    class="max-w-xs rounded-md shadow-sm"
                                                >
                                                    <input
                                                        type="number"
                                                        v-model="
                                                            pickupSettings.prepTimeInDays
                                                        "
                                                        placeholder=""
                                                        id="prepTimeInDays"
                                                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                                        :class="{
                                                            'border-red-300':
                                                                $v
                                                                    .pickupSettings
                                                                    .prepTimeInDays
                                                                    .$error
                                                        }"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <BaseSpacerWithRuler />

            <div>
                <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                        <div class="sm:pl-0 sm:pr-5">
                            <h3
                                class="text-base font-semibold leading-6 text-gray-800"
                                >Pickup times</h3
                            >
                            <p class="mt-2 text-sm text-gray-500">
                                Choose which days and times customers can order
                                pickups for.
                            </p>
                        </div>
                    </div>
                    <div
                        class="mt-5 md:col-span-2 md:mt-0"
                        v-if="!isLoading"
                        :class="{
                            'pointer-events-none': !acceptsPreOrders
                        }"
                    >
                        <div class="grid grid-cols-6 gap-3">
                            <div
                                class="col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"
                            >
                                <pickup-times
                                    :acceptsPreOrders="acceptsPreOrders"
                                    :pickupSlots="pickupSlots"
                                    :hoursDict="hoursDict"
                                    :closedArray="closedArray"
                                    :END_OF_THE_DAY="END_OF_THE_DAY"
                                    :validationError="
                                        !!errors && $v.pickupSlots.$invalid
                                    "
                                    @editedPickupTimes="editedPickupTimes"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BaseSpacerWithRuler />

            <div>
                <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                        <div class="sm:pl-0 sm:pr-5">
                            <h3
                                class="text-base font-semibold leading-6 text-gray-800"
                                >Specific pre-order days</h3
                            >
                            <p class="mt-2 text-sm text-gray-500">
                                Choose specific dates for pre-orders. These values will override normal weekdays, but inherit the same slot settings (time period, capacity etc)
                            </p>
                        </div>
                    </div>
                    <div class="mt-5 md:col-span-2 md:mt-0">
                        <div class="grid grid-cols-6 gap-3">
                            <div
                                class="col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"
                            >
                                <dates-selector
                                    :dates="preOrderDates.pickupDates"
                                    :maxDates="365"
                                    @selected="updatePreOrderDates"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BaseSpacerWithRuler />
        </div>

        <!--  DELIVERY SLOTS -->

        <div v-if="selectedTab === 'delivery'">
            <div>
                <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                        <div class="sm:pl-0 sm:pr-5">
                            <h3
                                class="text-base font-semibold leading-6 text-gray-800"
                                >Delivery pre-order details</h3
                            >
                            <p class="mt-2 text-sm text-gray-500">
                                Configure your how you'd like to receive
                                pre-orders
                            </p>
                        </div>
                    </div>
                    <div class="mt-5 md:col-span-2 md:mt-0">
                        <div class="grid grid-cols-6 gap-3">
                            <div
                                class="col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"
                            >
                                <div class="mt-5 md:col-span-2 md:mt-0">
                                    <div
                                        class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6"
                                    >
                                        <div
                                            :class="{
                                                'pointer-events-none cursor-not-allowed opacity-50': !acceptsPreOrders
                                            }"
                                            class="sm:col-span-6 md:pr-2"
                                        >
                                            <label
                                                for="slotDuration"
                                                class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Default time slot length
                                            </label>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                Split up the day into multiple
                                                time slots customers can choose
                                                from when they order.
                                            </p>
                                            <div
                                                class="mt-1 sm:col-span-2 sm:mt-2"
                                            >
                                                <div
                                                    class="max-w-xs rounded-md shadow-sm"
                                                >
                                                    <select
                                                        id="slotDuration"
                                                        v-model="
                                                            defaultSlotSettings
                                                                .delivery
                                                                .slotInterval
                                                        "
                                                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                                    >
                                                        <option
                                                            :value="duration"
                                                            v-for="duration in slotDurations"
                                                            :key="duration"
                                                            >{{
                                                                duration
                                                            }}
                                                            Minutes</option
                                                        >
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            :class="{
                                                'pointer-events-none cursor-not-allowed opacity-50': !acceptsPreOrders
                                            }"
                                            class="sm:col-span-6 md:pl-2"
                                        >
                                            <div
                                                class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Default max orders per slot
                                            </div>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                Limit the number of orders you
                                                allow per time slot. Leave empty
                                                for unlimited orders per time
                                                slot.
                                            </p>
                                            <div
                                                class="mt-1 sm:col-span-2 sm:mt-2"
                                            >
                                                <div
                                                    class="max-w-xs rounded-md shadow-sm"
                                                >
                                                    <input
                                                        type="number"
                                                        v-model="
                                                            defaultSlotSettings
                                                                .delivery
                                                                .slotQuantity
                                                        "
                                                        placeholder="Unlimited"
                                                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            :class="{
                                                'pointer-events-none cursor-not-allowed opacity-50': !acceptsPreOrders
                                            }"
                                            class="sm:col-span-6 md:pl-2"
                                        >
                                            <div
                                                class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Default max items per slot
                                            </div>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                Limit the number of items you
                                                allow per time slot. Leave empty
                                                for unlimited items per time
                                                slot.
                                            </p>
                                            <div
                                                class="mt-1 sm:col-span-2 sm:mt-2"
                                            >
                                                <div
                                                    class="max-w-xs rounded-md shadow-sm"
                                                >
                                                    <input
                                                        type="number"
                                                        v-model="
                                                            defaultSlotSettings
                                                                .delivery
                                                                .itemQuantity
                                                        "
                                                        placeholder="Unlimited"
                                                        min="0"
                                                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            :class="{
                                                'pointer-events-none cursor-not-allowed opacity-50': !acceptsPreOrders
                                            }"
                                            class="sm:col-span-6 md:pl-2"
                                        >
                                            <label
                                                for="pickupSettingsMonetaryLimit"
                                                class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Default monetary limit per slot
                                            </label>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                Limit the total amount of orders
                                                allowed for the time slot by the
                                                sum of order totals. E.g. set a
                                                limit of £200 and customers will
                                                be prevented from ordering more
                                                within a timeslot.
                                            </p>
                                            <div
                                                class="mt-1 sm:col-span-2 sm:mt-2"
                                            >
                                                <div
                                                    class="relative mt-1 max-w-xs rounded-md shadow-sm"
                                                >
                                                    <div
                                                        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                                    >
                                                        <span
                                                            class="text-gray-500 sm:text-sm sm:leading-5"
                                                        >
                                                            {{
                                                                currency.symbol
                                                            }}
                                                        </span>
                                                    </div>
                                                    <currency-input
                                                        id="pickupSettingsMonetaryLimit"
                                                        v-model="
                                                            defaultSlotSettings
                                                                .delivery
                                                                .monetaryLimit
                                                        "
                                                        class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                                        placeholder="Unlimited"
                                                        :currency="null"
                                                        locale="en-GB"
                                                        :value-as-integer="true"
                                                        :allow-negative="false"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            :class="{
                                                'pointer-events-none cursor-not-allowed opacity-50': !acceptsPreOrders
                                            }"
                                            class="sm:col-span-6 md:pl-2"
                                        >
                                            <label
                                                for="deliveryMaxFutureDays"
                                                class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Limit future date selection
                                            </label>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                How far in advance customers can
                                                place orders (in days).
                                            </p>
                                            <div
                                                class="mt-1 sm:col-span-2 sm:mt-2"
                                            >
                                                <div
                                                    class="max-w-xs rounded-md shadow-sm"
                                                >
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        v-model="
                                                            defaultSlotSettings
                                                                .delivery
                                                                .maxFutureDays
                                                        "
                                                        id="deliveryMaxFutureDays"
                                                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                                        :class="{
                                                            'border-red-300':
                                                                $v
                                                                    .defaultSlotSettings
                                                                    .delivery
                                                                    .maxFutureDays
                                                                    .$error
                                                        }"
                                                    />
                                                </div>
                                                <span
                                                    v-if="
                                                        $v.defaultSlotSettings
                                                            .delivery
                                                            .maxFutureDays
                                                            .$error
                                                    "
                                                    class="mt-1 text-xs text-red-300"
                                                >
                                                    Must be 1 or greater
                                                </span>
                                            </div>
                                        </div>

                                        <div
                                            :class="{
                                                'pointer-events-none cursor-not-allowed opacity-50': !acceptsPreOrders
                                            }"
                                            class="sm:col-span-6 md:pl-2"
                                        >
                                            <label
                                                for="deliveryPrepTimeInDays"
                                                class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                            >
                                                Order cut-off
                                            </label>
                                            <p
                                                class="mt-1 text-sm leading-5 text-gray-500"
                                            >
                                                Enter a number of days you'll
                                                need to prepare for pickup.
                                                Enter 0 for same day pickup.
                                            </p>
                                            <div
                                                class="mt-1 sm:col-span-2 sm:mt-2"
                                            >
                                                <div
                                                    class="max-w-xs rounded-md shadow-sm"
                                                >
                                                    <input
                                                        type="number"
                                                        v-model="
                                                            defaultSlotSettings
                                                                .delivery
                                                                .prepTimeInDays
                                                        "
                                                        placeholder=""
                                                        id="deliveryPrepTimeInDays"
                                                        class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                                        :class="{
                                                            'border-red-300':
                                                                $v
                                                                    .defaultSlotSettings
                                                                    .delivery
                                                                    .prepTimeInDays
                                                                    .$error
                                                        }"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BaseSpacerWithRuler />

            <div>
                <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                        <div class="sm:pl-0 sm:pr-5">
                            <h3
                                class="text-base font-semibold leading-6 text-gray-800"
                                >Delivery times</h3
                            >
                            <p class="mt-2 text-sm text-gray-500">
                                Choose which days and times customers can order
                                pickups for.
                            </p>
                        </div>
                    </div>
                    <div
                        class="mt-5 md:col-span-2 md:mt-0"
                        v-if="!isLoading"
                        :class="{
                            'pointer-events-none': !acceptsPreOrders
                        }"
                    >
                        <div class="grid grid-cols-6 gap-3">
                            <div
                                class="col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"
                            >
                                <preorder-times
                                    :acceptsPreOrders="acceptsPreOrders"
                                    :pickupSlots="deliverySlots"
                                    :hoursDict="hoursDict"
                                    :END_OF_THE_DAY="END_OF_THE_DAY"
                                    :validationError="
                                        !!errors && $v.deliverySlots.$invalid
                                    "
                                    :defaultSettings="
                                        defaultSlotSettings.delivery
                                    "
                                    @editedPickupTimes="editedDeliveryTimes"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <BaseSpacerWithRuler />

            <div>
                <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                        <div class="sm:pl-0 sm:pr-5">
                            <h3
                                class="text-base font-semibold leading-6 text-gray-800"
                                >Specific pre-order days</h3
                            >
                            <p class="mt-2 text-sm text-gray-500">
                                Choose specific dates for pre-orders instead of
                                using standard weekly days.
                            </p>
                        </div>
                    </div>
                    <div class="mt-5 md:col-span-2 md:mt-0">
                        <div class="grid grid-cols-6 gap-3">
                            <div
                                class="col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"
                            >
                                <dates-selector
                                    :dates="preOrderDates.deliveryDates"
                                    :maxDates="365"
                                    @selected="updatePreOrderDates"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BaseSpacerWithRuler />
        </div>

        <div
            class="my-6 ml-4 flex flex-shrink-0 flex-col items-end justify-end"
        >
            <div v-if="!!errors" class="block">
                <p class="mb-2 text-sm text-red-400">
                    Please add opening times for each day
                </p>
            </div>

            <span class="inline-flex rounded-md shadow-sm" @click="saveSlots">
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>

        <!--        <FooterHelp label="notices" link="https://storekit.com" />-->
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { minLength, minValue, required } from 'vuelidate/lib/validators';
import PickupTimes from '../../editVenue/PickupTimes';
import ToggleSwitch from '../../formComponents/ToggleSwitch.vue';
import PreorderTimes from '../../editVenue/PreorderTimes';
import DeliveryIcon from '@/components/common/DeliveryIcon';
import PickupIcon from '@/components/common/PickupIcon';
import DatesSelector from '@/components/selectors/DatesSelector';
import { FeaturesEnum } from '@/enums';

export default {
    name: 'PickupSlotSettings',
    props: ['venueId', 'venue'],
    metaInfo: {
        title: 'Pre Order Settings'
    },
    components: {
        PickupTimes,
        PreorderTimes,
        ToggleSwitch,
        PickupIcon,
        DeliveryIcon,
        DatesSelector
    },
    data() {
        return {
            FeaturesEnum,
            isLoading: true,
            errors: null,
            acceptsPreOrders: this.venue.acceptsPreOrders,
            slotSettings: { ...this.venue },
            END_OF_THE_DAY: '23:59',
            selectedTab: 'pickup',
            pickupSettings: {
                pickupSlotQuantity: null,
                maxFutureDays: 7,
                prepTimeInDays: 0,
                slotInterval: 5,
                monetaryLimit: null
            },
            hoursDict: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
            ],
            slotDurations: [5, 10, 15, 20, 30, 45, 60, 90, 120, 180, 240],
            pickupSlots: [
                {
                    day: 0,
                    openTime: '',
                    closeTime: '',
                    quantity: null,
                    itemQuantity: null,
                    maxFutureDays: 7,
                    prepTimeInDays: 0,
                    slotInterval: 15,
                    active: true
                },
                {
                    day: 1,
                    openTime: '',
                    closeTime: '',
                    quantity: null,
                    itemQuantity: null,
                    maxFutureDays: 7,
                    prepTimeInDays: 0,
                    slotInterval: 15,
                    active: true
                },
                {
                    day: 2,
                    openTime: '',
                    closeTime: '',
                    quantity: null,
                    itemQuantity: null,
                    maxFutureDays: 7,
                    prepTimeInDays: 0,
                    slotInterval: 15,
                    active: true
                },
                {
                    day: 3,
                    openTime: '',
                    closeTime: '',
                    quantity: null,
                    itemQuantity: null,

                    maxFutureDays: 7,
                    prepTimeInDays: 0,
                    slotInterval: 15,
                    active: true
                },
                {
                    day: 4,
                    openTime: '',
                    closeTime: '',
                    quantity: null,
                    itemQuantity: null,
                    maxFutureDays: 7,
                    prepTimeInDays: 0,
                    slotInterval: 15,
                    active: true
                },
                {
                    day: 5,
                    openTime: '',
                    closeTime: '',
                    quantity: null,
                    itemQuantity: null,
                    maxFutureDays: 7,
                    prepTimeInDays: 0,
                    slotInterval: 15,
                    active: true
                },
                {
                    day: 6,
                    openTime: '',
                    closeTime: '',
                    quantity: null,
                    itemQuantity: null,
                    maxFutureDays: 7,
                    prepTimeInDays: 0,
                    slotInterval: 15,
                    active: true
                }
            ],
            deliverySlots: [
                {
                    slotInterval: null,
                    day: 0,
                    openTime: '',
                    closeTime: '',
                    quantity: null,
                    itemQuantity: null,
                    active: false,
                    monetaryLimit: null,
                    showOptions: false
                },
                {
                    slotInterval: null,
                    day: 1,
                    openTime: '',
                    closeTime: '',
                    quantity: null,
                    itemQuantity: null,
                    active: false,
                    monetaryLimit: null,
                    showOptions: false
                },
                {
                    slotInterval: null,
                    day: 2,
                    openTime: '',
                    closeTime: '',
                    quantity: null,
                    itemQuantity: null,
                    active: false,
                    monetaryLimit: null,
                    showOptions: false
                },
                {
                    slotInterval: null,
                    day: 3,
                    openTime: '',
                    closeTime: '',
                    quantity: null,
                    itemQuantity: null,
                    active: false,
                    monetaryLimit: null,
                    showOptions: false
                },
                {
                    slotInterval: null,
                    day: 4,
                    openTime: '',
                    closeTime: '',
                    quantity: null,
                    itemQuantity: null,
                    active: false,
                    monetaryLimit: null,
                    showOptions: false
                },
                {
                    slotInterval: null,
                    day: 5,
                    openTime: '',
                    closeTime: '',
                    quantity: null,
                    itemQuantity: null,
                    active: false,
                    monetaryLimit: null,
                    showOptions: false
                },
                {
                    slotInterval: null,
                    day: 6,
                    openTime: '',
                    closeTime: '',
                    quantity: null,
                    itemQuantity: null,
                    active: false,
                    monetaryLimit: null,
                    showOptions: true
                }
            ],
            defaultSlotSettings: {
                delivery: {
                    slotQuantity: null,
                    itemQuantity: null,
                    maxFutureDays: 7,
                    prepTimeInDays: 0,
                    slotInterval: 5,
                    monetaryLimit: null
                }
            },
            preOrderDates: {
                pickupDates: this.venue?.preOrderDates?.pickupDates || [],
                deliveryDates: this.venue?.preOrderDates?.deliveryDates || []
            }
        };
    },
    validations: {
        pickupSlots: {
            required,
            minLength: minLength(7),
            $each: {
                handler(slot) {
                    return !(
                        slot.openTime.length < 4 &&
                        slot.closeTime.length < 4 &&
                        slot.active
                    );
                }
            }
        },
        deliverySlots: {
            required,
            $each: {
                required
            }
        },
        pickupSettings: {
            maxFutureDays: {
                required,
                minValue: minValue(0)
            },
            prepTimeInDays: {
                required
            },
            slotInterval: {
                required
            }
        },
        defaultSlotSettings: {
            delivery: {
                maxFutureDays: {
                    required,
                    minValue: minValue(0)
                },
                prepTimeInDays: {
                    required
                },
                slotInterval: {
                    required
                }
            }
        }
    },
    validationGroup: ['pickupSettings', 'pickupSlots'],
    async created() {
        this.fetchSlots();
    },
    computed: {
        ...mapGetters({
            currency: 'user/currencySettings',
            isFeatureAvailable: 'user/isFeatureAvailable',
        }),
        isPickup() {
            return 'pickup' === this.selectedTab;
        }
    },
    methods: {
        ...mapActions({
            updateVenueSettings: 'venues/update'
        }),
        editedPickupTimes(slots) {
            this.pickupSlots = slots;
        },
        editedDeliveryTimes(slots) {
            this.deliverySlots = slots;
        },
        async fetchSlots() {
            this.isLoading = true;

            try {
                if (this.venue.defaultSlotSettings?.delivery) {
                    this.defaultSlotSettings = this.venue.defaultSlotSettings;
                }

                const { data } = await this.$axios.get(
                    '/venues/' + this.venueId + '/slots'
                );

                if (data?.pickupSlots?.length) {
                    this.pickupSlots = data.pickupSlots;
                    this.pickupSettings.maxFutureDays =
                        data.pickupSlots[0].maxFutureDays;
                    this.pickupSettings.prepTimeInDays =
                        data.pickupSlots[0].prepTimeInDays;
                    this.pickupSettings.slotInterval =
                        data.pickupSlots[0].slotInterval;
                    this.pickupSettings.pickupSlotQuantity =
                        data.pickupSlots[0].quantity;
                    this.pickupSettings.monetaryLimit =
                        data.pickupSlots[0].monetaryLimit;
                    this.pickupSettings.itemQuantity =
                        data.pickupSlots[0].itemQuantity;
                }

                if (data?.deliverySlots?.length) {
                    this.deliverySlots = data.deliverySlots;
                }
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },

        async savePickupSlots() {
            const slots = this.pickupSlots.map(v => ({
                ...v,
                prepTimeInDays: Math.abs(
                    parseInt(this.pickupSettings.prepTimeInDays)
                ),
                maxFutureDays: Math.abs(
                    parseInt(this.pickupSettings.maxFutureDays)
                ),
                slotInterval: parseInt(this.pickupSettings.slotInterval),
                monetaryLimit:
                    Math.abs(parseInt(this.pickupSettings.monetaryLimit)) ||
                    null,
                quantity:
                    Math.abs(
                        parseInt(this.pickupSettings.pickupSlotQuantity)
                    ) || null,
                itemQuantity:
                    Math.abs(parseInt(this.pickupSettings.itemQuantity)) || null
            }));

            await this.$axios.put('/venues/slots', {
                slots,
                type: 'pickup',
                venueId: this.venue.id
            });
        },

        async saveDeliverySlots() {
            const deliverySlots = this.deliverySlots.map(slot => {
                return {
                    active: slot.active,
                    openTime: slot.openTime,
                    closeTime: slot.closeTime,
                    day: slot.day,
                    id: slot.id,
                    monetaryLimit: slot.monetaryLimit,
                    slotInterval: parseInt(slot.slotInterval),
                    quantity: parseInt(slot.quantity),
                    itemQuantity: parseInt(slot.itemQuantity)
                };
            });

            await this.$axios.put('/venues/slots', {
                slots: deliverySlots,
                type: 'delivery',
                venueId: this.venue.id
            });
        },

        async saveSlots() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.errors = 'ERROR';
                return false;
            }

            this.isLoading = true;

            try {
                await this.saveDeliverySlots();
                await this.savePickupSlots();

                this.defaultSlotSettings.delivery = {
                    ...this.defaultSlotSettings.delivery,
                    maxFutureDays: Math.abs(
                        parseInt(
                            this.defaultSlotSettings.delivery.maxFutureDays
                        )
                    ),
                    prepTimeInDays: Math.abs(
                        parseInt(
                            this.defaultSlotSettings.delivery.prepTimeInDays
                        )
                    ),
                    slotQuantity:
                        Math.abs(
                            parseInt(
                                this.defaultSlotSettings.delivery.slotQuantity
                            )
                        ) || null,
                    itemQuantity:
                        Math.abs(
                            parseInt(
                                this.defaultSlotSettings.delivery.itemQuantity
                            )
                        ) || null
                };

                await this.updateVenueSettings({
                    id: this.venue.id,
                    defaultSlotSettings: this.defaultSlotSettings,
                    preOrderDates: this.preOrderDates
                });

                this.fetchSlots();
                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async togglePreOrder() {
            this.isLoading = true;
            const payload = {
                id: this.venue.id,
                acceptsPreOrders: !this.acceptsPreOrders
            };

            try {
                await this.updateVenueSettings(payload);

                this.acceptsPreOrders = !this.acceptsPreOrders;

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        updateVenue(value) {
            this.isLoading = true;
            const venue = {
                [value]: !this.slotSettings[value]
            };
            this.slotSettings[value] = !this.slotSettings[value];
            if (venue) {
                this.$axios
                    .put('/venues/' + this.venue.id, venue)
                    .then(() => {
                        this.$notify({
                            group: 'settings',
                            title: 'Settings saved'
                        });

                        if (value === 'acceptsPreOrders') {
                            this.acceptsPreOrders = !this.acceptsPreOrders;
                        }
                    })
                    .catch(e => {
                        this.slotSettings[value] = !this.slotSettings[value];
                        throw new Error(`API ${e}`);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        selectTab(value) {
            this.selectedTab = value;
        },
        updatePreOrderDates(val) {
            const preOrderType =
                this.selectedTab === 'pickup' ? 'pickupDates' : 'deliveryDates';

            this.preOrderDates[preOrderType] = val;
            this.preOrderDates[preOrderType].sort(
                (a, b) => new Date(a) - new Date(b)
            );
        }
    }
};
</script>
